body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group {
  display: flex;
  justify-content: space-between;
}

.root-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.content-container {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.sarRow {
  padding: 0.85rem 0;
}

.vCenter {
  margin: auto 0;
}

.replaceOld {
  color: red;
  text-decoration: line-through red;
}

.replaceNew {
  color: darkgreen;
  text-decoration: underline darkgreen;
}

.replaceCommand {
  height: unset;
  padding: 0;
  margin-left: 4px;
}

.replaceCommand .ms-Button-flexContainer {
  border-left: 1px solid lightgrey;
}

.h100 {
  height: 100%;
}

.noscroll {
  overflow: hidden;
}

.scroll {
  overflow: auto;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-fixed {
  flex-shrink: 0;
}

.flex-stretch {
  flex-grow: 1;
  overflow: hidden;
}

div.ms-BasePicker-text {
  box-sizing: content-box;
}

.pt10 {
  padding-top: 10px;
}

.no-overflow {
  overflow: hidden;
}

.flex-fixed label {
  margin-top: 10px;
}